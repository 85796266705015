<template>
  <v-container fluid v-if="program">
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color2 color2Text--text">
            <v-toolbar-title>{{program.title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="color3 color3Text--text"
              fab
              small
              @click.stop="loadProgram"
              v-if="user && user.vbl && !publicView"
            >
              <v-icon small>fas fa-sync-alt</v-icon>
            </v-btn>
          </v-toolbar>
          <loading-bar :value="loading"/>
          <router-view
            :program="program"
            :username="subdomain"
            :loading="loading"
            @level-selected="onLevelSelected"
            @new-sember="onNewMember"
            @go-search="onGoSearch"
          ></router-view>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import * as mutations from '@/store/MutationTypes'
import { firstBy } from 'thenby'

export default {
  data () {
    return {
      program: null,
      loading: false,
      snack: false,
      autoSearch: null,
      level: null,
      newMember: null
    }
  },
  computed: {
    ...mapGetters(['subdomain', 'organizationName', 'user', 'publicView']),
    proactive () {
      return this.error && this.error.id
    },
    images () {
      return this.program && this.program.imageList.split(',')
    },
    qLevel () {
      return this.$route.query.level
    }
  },
  methods: {
    onLevelSelected (level) {
      this.level = level
    },
    onNewMember (member) {
      this.newMember = member
      this.$router.push({ name: 'member-levels' })
    },
    onGoSearch (val) {
      this.$router.push({ name: 'member-search' })
      this.autoSearch = val
    },
    onLoading (val) {
      this.loading = val
    },
    loadProgram () {
      this.$store.commit(mutations.LOADING, 'membership')
      this.$VBL.get.membership(this.subdomain)
        .then(r => {
          if (r.data) {
            this.program = r.data
            this.program.levels && this.program.levels.sort(firstBy('order'))
          } else {
            this.$router.push({ name: 'home' })
          }
        })
        .catch(e => {
          console.log(e.response)
          this.$router.push({ name: 'home' })
        })
        .finally(() => {
          this.$store.commit(mutations.LOADING_COMPLETE, 'membership')
        })
    }
  },
  mounted () {
    this.loadProgram()
  },
  components: {
  }
}
</script>

<style>

</style>
